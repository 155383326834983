var generic = generic || {};

(function ($) {
  // Open loyalty cancel content
  var cancelLoyaltyLink = '';
  var cancelLoyaltySubmit;
  var chk_address_legal;
  var chk_profile_text;
  var loyaltyCancelResponse;

  if ($('#settings').length > 0 && !($('#settings').is('symbol'))) {
    cancelLoyaltyLink = $('#settings .js-profile-info a.loyalty_cancel');
  } else if ($('#profile_preferences').length > 0) {
    cancelLoyaltyLink = $('#profile_preferences .loyalty_form_container a.loyalty_cancel');
  }

  if (cancelLoyaltyLink !== '') {
    cancelLoyaltyLink.on('click', function () {
      var $csrfToken = $.cookie('csrftoken');

      generic.overlay.launch({
        content: $("script.inline-template[path='loyalty_cancellation_popup']").html(),
        width: 535,
        height: 310,
        cssClass: 'loyalty-cancel-overlay',
        onShow: function () {
          if ($('.loyalty_cancel_link').length > 0) {
            cancelLoyaltySubmit = $('.loyalty_cancel_link a').attr('href');
          } else {
            cancelLoyaltySubmit = '/account/profile_preferences.tmpl';
          }
        }
      });
      // Do not vertical align the popup in mobile.
      if ($('body').hasClass('device-mobile')) {
        $('#foreground-node').css('top', '0');
      }
      $('.loyalty_popover__cancel_terms-text').addClass('hidden');
      $('.loyalty-cancel-overlay a.cancel_loyalty_submit').on('click', function () {
        // Cancel loyalty only if checkbox is enabled.
        if ($('#loyalty_cancellation_popup_cancel_confirm').is(':checked')) {
          loyaltyCancelResponse = $.post('/account/loyalty/index.tmpl', {
            _SUBMIT: 'loyalty_join',
            LEAVE_CONFIRM: 1,
            LEAVE_CONFIRM_PRESENT: 1,
            _token: $csrfToken ? $csrfToken : ''
          });

          loyaltyCancelResponse.done(function () {
            $('.close-link').trigger('click');
            // Open loyalty cancel confirmation content
            generic.overlay.launch({
              content: $(
                "script.inline-template[path='loyalty_cancellation_confirm_popup']"
              ).html(),
              width: 535,
              height: 310,
              cssClass: 'loyalty-cancellation-confirm-overlay'
            });
            $('.loyalty-cancellation-confirm-overlay a.rejoin_loyalty_program').on('click', function () {
              $('#clinique_loyalty_join_popup_form').trigger('submit');
            });
            $('.loyalty-cancellation-confirm-overlay .close-link').on('click', function () {
              window.location.href = cancelLoyaltySubmit;
            });
            generic.rb.language = generic.rb('language');
            chk_address_legal = generic.rb.language.get('chk_address_legal');
            chk_profile_text = generic.rb.language.get('chk_profile_text');
            $('.loyalty_cancellation_confirm_popup .js-info-link').html(chk_address_legal);
            $('.loyalty_cancellation_confirm_popup .info-message-txt').html(chk_profile_text);
          });
        } else {
          $('.loyalty_popover__cancel_terms-text').removeClass('hidden');
        }
      });
    });
  }
})(jQuery);
